import React from 'react';
import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
import { FeatureWithTiles } from '../Widgets/Promo/FeatureWithTiles.tsx';
import { FeaturesWithCards } from '../Widgets/Promo/FeatureWithCards.tsx';
import { ThreeTieredPricing } from '../Widgets/Promo/ThreeTieredPricing.tsx';
import { Space } from '@mantine/core';

  export default function AboutUs() {

    const featureTilesData = 
        {
         "data":[
          {
            "image": "auditors",
            "title": "Web Producers",
            "description": "Can a before and after snapshot of your content entry changes",
            "icon": <IconGauge></IconGauge>
          },
          {
            "image": "lawyers",
            "title": "Product Owners",
            "description": "Get an overall view of your product that you can share on socials",
            "icon": <IconGauge></IconGauge>
          },
          {
            "image": "accountants",
            "title": "Developers",
            "description": "Easily E2E or regression test your major changes",
            "icon": <IconGauge></IconGauge>
          },
          {
            "image": "others",
            "title": "Content Creators",
            "description": "Snapshot the web to use in content for a blog or social post",
            "icon": <IconGauge></IconGauge>
          }
        ]};

    return (
        <>
            <Space h="xl"></Space>
            <FeaturesWithCards></FeaturesWithCards>
            <FeatureWithTiles supTitle="Welcome!" description="Anyone can take a screenshot!" data={featureTilesData.data}></FeatureWithTiles>
            <ThreeTieredPricing></ThreeTieredPricing>
            <Space h="xl"></Space> <Space h="xl"></Space>
            <div className="ml-embedded" data-form="KkwI8T"></div>
        </>
    );
  }