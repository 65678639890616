import {React, useState, useEffect} from 'react';
import axios from 'axios';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { Box, Tabs, PasswordInput, Group, LoadingOverlay, Checkbox, Button, Anchor, Container, Paper, TextInput, Title } from '@mantine/core';
import { GoogleButton } from '../Components/Buttons/GoogleButton.tsx';

function Login({onLoginClick, onSignupClick, isBackendLoading}) {
    return (
        <Box maw={400} pos="relative" style={{ margin: "0 auto"}}>
            <LoadingOverlay visible={isBackendLoading} overlayBlur={2} />
        {/* ...other content */}
        <Container size={420} my={40}>
            <Title
                align="center"
                sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
                Welcome to Snappy!
            </Title>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Tabs color="teal" defaultValue="first">
                    <Tabs.List>
                        <Tabs.Tab value="first">Login</Tabs.Tab>
                        <Tabs.Tab value="second" color="blue">
                            Signup
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="first" pt="xs">
                        <Group grow mb="md" mt="md">
                            <GoogleButton onClick={onLoginClick} radius="md">Login with Google</GoogleButton>
                        </Group>

                    </Tabs.Panel>

                    <Tabs.Panel value="second" pt="xs">
                        <Group grow mb="md" mt="md">
                            <GoogleButton onClick={onSignupClick} radius="md">Sign up with Google</GoogleButton>
                        </Group>
                    </Tabs.Panel>
                </Tabs>
            </Paper>
        </Container>
        </Box>
    )
}
export default Login;