function clearCookie(name, domain, path){
    domain = domain || document.domain;
    path = path || "/";
    document.cookie = name + "=; expires=" + +new Date + "; domain=" + domain + "; path=" + path;
};

export function isValidURL(url) {
    // Regular expression for URL validation
    const urlRegex = /^(?=.*[a-zA-Z])[^.]*\..*(?=[a-zA-Z])/;
    console.log(url, "URL");
    console.log(urlRegex.test(url), "is valid url");
    console.log(urlRegex.test("text.with"));
    return urlRegex.test(url);
}

export default clearCookie;
