import React from 'react'
import { Slider, ActionIcon,NumberInput, Text, Grid } from '@mantine/core';
import { useState, useEffect } from 'react';
import { IconAdjustments, IconAward } from '@tabler/icons';

// Could eventually make configurable
const MARKS = [
    { value: 0, label: 'Mobile', dimension: "390x844", height: 844, width: 390 },
    { value: 33, label: 'Tablet', dimension: "768x1024", height: 1024, width: 768 },
    { value: 66, label: 'Desktop', dimension: "1366x768", height: 768, width: 1366 },
    { value: 99, label: 'Desktop XL', dimension: "1920x1080", height: 1080, width: 1920 },
];
const pstyle = {
    margin: '0',
    'textAlign': 'center'
};

export default function SizeSlider({ onChange, heightDisabled }) {
    const [sliderValue, setSliderValue] = useState(66);
    const [screenSize, setScreenSize] = useState({height: 768, width: 1366})

    const onSliderChange = function (value) {
        setSliderValue(value);
        let screenSize = MARKS.find((mark) => mark.value === value).dimension;
        console.log(MARKS.find((mark) => mark.value === value).dimension)
    }

    const onTextboxSizeChange = function (attribute, value){
        console.log(attribute, value, "value")
        setScreenSize({ ...screenSize, [attribute]: value });
    }

    useEffect(() => {
        setScreenSize({"height": MARKS.find((mark) => mark.value === sliderValue).height,  
        "width": MARKS.find((mark) => mark.value === sliderValue).width});
        console.log(sliderValue);
    }, [sliderValue]); // <-- dependency array

    useEffect(() => {
        console.log(screenSize,"testing")
        onChange(screenSize.width + 'x' + screenSize.height);
    },[screenSize]);

    return (
        <>
            <Slider
                onChange={onSliderChange}
                disabled={heightDisabled}
                label={(val) => MARKS.find((mark) => mark.value === val).label}
                defaultValue={66}
                step={33}
                marks={MARKS}
                styles={{ markLabel: { display: 'none' } }}
            />
            <p style={pstyle}> Size: {MARKS.find((mark) => mark.value === sliderValue).dimension} ({MARKS.find((mark) => mark.value === sliderValue).label})</p>
            <Grid>
            <Grid.Col xs={6}>  
                <NumberInput
                        min={240}
                        max={6000}
                        onChange={(event) => onTextboxSizeChange("width", event)}
                        value={screenSize.width}
                        style={{width:"100%"}}
                        label="Width"
                        />                              
            </Grid.Col>
            <Grid.Col xs={6}>           
                <NumberInput
                        min={240}
                        max={6000}
                        disabled={heightDisabled}
                        value={screenSize.height}
                        onChange={(event) => onTextboxSizeChange("height", event)}
                        style={{width:"100%"}}
                        label="Height"
                        />               
            </Grid.Col>
            </Grid>
            
        </>
    )
}
