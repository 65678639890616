import React from 'react'
import { Tooltip, Text, ActionIcon, createStyles}
  from '@mantine/core';
  import { IconHelp } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    noMargin: {
        marginLeft: "-12px" 
      },
    fontWeight:{
        fontWeight:"normal"
    }
}));

export default function HelpRollover({rolloverText}) {
    const { classes } = useStyles();

    return (
    <Tooltip className={classes.fontWeight} label={
        <>
        <Text className={classes.fontWeight}>{rolloverText}</Text>
        </>
    } 
    position="right">
        <ActionIcon className={classes.noMargin}>
            <IconHelp size="1rem"></IconHelp>
        </ActionIcon>
    </Tooltip>
    )
}
