import React from 'react'
import { createStyles, Container } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  sceneryContainer:{
    position: "relative"
  },
  leftContainer:{
    position: "relative"
  },
  rightContainer:{
    position: "relative",
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      // display: "none",
      backgroundColor: "red",
    },
  },
  launchPad:{
    left: "175px",
    top: "90px",
    position: "inherit",
    height:"220px"
  },
  tree1:{
    position: "inherit",
    bottom: "-83px",
    left: "145px",
    height:"70px"
  }
}))

export default function FooterScenery() {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.sceneryContainer}>
        <img className={classes.launchPad} loading="lazy" src="scenery/launchpadog.png" alt="rock"/>
        <div style={{"left":"250px", "bottom":"62px", "width":"150px", "height": "1px"}} className={classes.leftContainer}>
          <img className={classes.tree1} loading="lazy" src="scenery/tree1.png" alt="tree"/>
          <img style={{"left":"250px"}} className={classes.tree1} loading="lazy" src="scenery/tree2.png" alt="tree"/>
          <img style={{"left":"160px", "bottom":"-105px", "height":"25px"}} className={classes.tree1} loading="lazy" src="scenery/rock2.png" alt="tree"/>
        </div>
        <Container style={{"right":"-25vw", "bottom":"62px", "width":"150px"}} className={classes.rightContainer} >
          <img style={{"left":"110px", "height":"25px", "top":"105px"}} className={classes.tree1} loading="lazy" src="scenery/bench.png" alt="tree"/>
          <img className={classes.tree1} loading="lazy" src="scenery/lamp.png" alt="tree"/>
          
          <img style={{"left":"160px", "bottom":"-105px", "height":"25px"}} className={classes.tree1} loading="lazy" src="scenery/rock2.png" alt="tree"/>
        </Container>
      </div>
         
      <div style={{height: "62px", backgroundImage:"url('earth2.png')"}}></div>
    </>
  )
}
