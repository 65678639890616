import React from 'react';
import { Space, Text, Container } from '@mantine/core';

export default function PrivacyPolicy() {
  return (
    <Container>
        <Space h="md"></Space>
        <Text size="lg" weight={700}>
          Privacy Policy
        </Text>
        <Text>
          Effective Date: August 2, 2023
        </Text>
        <Text size="md" weight={700} style={{ marginTop: '16px' }}>
          1. Introduction
        </Text>
        <Text>
        Welcome to Site Snappy! This Privacy Policy governs the manner in which Site Snappy ("we," "our," or "us") collects, uses, maintains, and discloses information collected from users ("you" or "your") of the website https://www.sitesnappy.com ("Site").
        </Text>

        <Text size="md" weight={700} style={{ marginTop: '16px' }}>
          2. Information We Collect
        </Text>
        <Text>
          Personal Information:
          We may collect both personally identifiable information ("Personal Information") and non-personally identifiable information ("Non-Personal Information") from you when you use our Site. Personal Information may include but is not limited to your name, email address, phone number, and other information you voluntarily provide to us. Non-Personal Information may include your IP address, browser type, device information, and usage data.
        </Text>

        <Text size="md" weight={700} style={{ marginTop: '16px' }}>
          3. Non-Personal Information:
        </Text>
        <Text>
          We may use the information we collect from you for the following purposes:

          a. To personalize your experience: We may use your information to understand how you use our Site and provide content and features that match your preferences.

          b. To improve our services: Your feedback and information help us enhance and optimize our Site and offerings.

          c. To process transactions: We may use your information to process payments and fulfill orders for products or services you request.

          d. To send periodic emails: We may use your email address to send you updates, newsletters, and other information related to our services. You may opt-out of receiving marketing emails at any time by following the instructions provided in the email.
        </Text>

        <Text size="md" weight={700} style={{ marginTop: '16px' }}>
          4. How We Protect Your Information
        </Text>
        <Text>
        We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your Personal Information, username, password, transaction information, and data stored on our Site.
        </Text>
        <Text size="md" weight={700} style={{ marginTop: '16px' }}>
          5. Sharing Your Information
          </Text>
          <Text>
          We do not sell, trade, or rent your Personal Information to others. However, we may share your information with trusted third parties who assist us in operating our Site, conducting business activities, or servicing you, as long as those parties agree to keep this information confidential.

  We may also disclose your information in the following circumstances:

  a. With your consent: We may share your information with third parties if you provide explicit consent.

  b. Legal requirements: We may disclose your information when required by law or in response to valid requests by public authorities.

  c. Protection of rights: We may disclose your information to enforce our terms of service, protect our rights, privacy, safety, or property, or that of others.
          </Text>

          <Text size="md" weight={700} style={{ marginTop: '16px' }}>
            6. Third-Party Links
          </Text>
          <Text>
          Our Site may contain links to third-party websites, plugins, or services. We do not control the content or links that appear on these sites and are not responsible for their practices. Please be aware that these websites may have their own privacy policies, which we recommend reviewing before providing any information to them.
          </Text>

          <Text size="md" weight={700} style={{ marginTop: '16px' }}>
            7. Children's Privacy
          </Text>

          <Text>
          Our Site is not intended for use by children under the age of 13. We do not knowingly collect Personal Information from children under 13. If you are a parent or guardian and believe that your child has provided us with Personal Information, please contact us at ben@sitesnappy.com, and we will promptly remove such information from our records.
          </Text>

          <Text size="md" weight={700} style={{ marginTop: '16px' }}>
            8. Changes to this Privacy Policy
          </Text>
          <Text>
            We reserve the right to update this Privacy Policy at any time. When we do, we will revise the "Effective Date" at the top of this page. We encourage you to check this page frequently for any changes to stay informed about how we are protecting the Personal Information we collect. Your continued use of the Site after any modifications to this Privacy Policy will constitute your acknowledgment of the changes and your consent to abide and be bound by the updated Privacy Policy.
          </Text>
          <Text size="md" weight={700} style={{ marginTop: '16px' }}>
            9. Contact Us
          </Text>
          <Text>
            If you have any questions or concerns about this Privacy Policy or the practices of this Site, please contact us at ben@sitesnappy.com
          </Text>
        
        {/* Rest of the content */}
  
    </Container>
  )
}
