import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps, rem, Container, Center, UnstyledButton, Avatar, Group, Text, Badge, RingProgress, Header, Title, Divider, Code, Paper, Grid, Space, Button } from '@mantine/core';
import { IconPhoto, IconMessageCircle, IconSettings, IconAdjustments } from '@tabler/icons-react';
import axios from 'axios';
import {
  useNavigate
} from "react-router-dom";
import clearCookie from '../../cookies'

function StyledTabs(props: TabsProps) {
  return (
    <Tabs
      unstyled
      styles={(theme) => ({
        tab: {
          ...theme.fn.focusStyles(),
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
          border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
          padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          cursor: 'pointer',
          fontSize: theme.fontSizes.sm,
          display: 'flex',
          alignItems: 'center',

          '&:disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
          },

          '&:not(:first-of-type)': {
            borderLeft: 0,
          },

          '&:first-of-type': {
            borderTopLeftRadius: theme.radius.md,
            borderBottomLeftRadius: theme.radius.md,
          },

          '&:last-of-type': {
            borderTopRightRadius: theme.radius.md,
            borderBottomRightRadius: theme.radius.md,
          },

          '&[data-active]': {
            backgroundColor: theme.colors.blue[7],
            borderColor: theme.colors.blue[7],
            color: theme.white,
          },
        },

        tabIcon: {
          marginRight: theme.spacing.xs,
          display: 'flex',
          alignItems: 'center',
        },

        tabsList: {
          display: 'flex',
        },
      })}
      {...props}
    />
  );
}

function getPercentQuotaUsed(limit, numberUsed) {
  let percent = (1 - (limit - numberUsed) / limit) * 100;
  return percent;
}

export default function DashboardHome({ onLogout }) {
  const navigate = useNavigate();
  //
  const [dashboardState, setDashboardState] = useState(
    {
      id: 1,
      firstName: "John",
      lastName: "Database",
      email: "ben@sitesnappy.com",
      monthlyApiCallsLimit: 200,
      accountLoginType: 0,
      last24HourCallAmount: 29,
      lastApiCall: '',
      lastLogin: '',
      apiCallsCount: 3,
      apiKey: ''
    }
  );

  useEffect(() => {
    const res = axios.get(`http://api.snappytest.com:7255/dashboard/home`, { withCredentials: true })
      .then(response => {
        console.log(response, "response")
        setDashboardState(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          //navigate('/login')
          onLogout();
        } else {
          alert("Error reaching backend server.")
        }
      })
      .finally(() => {

      });
    console.log(res);
  }, []);

  return (
    <Container my="md">
      <Center>
        <StyledTabs defaultValue="dashboard">
          <Tabs.List >
            <Tabs.Tab value="dashboard" icon={<IconAdjustments size="1rem" />}>
              Dashboard
            </Tabs.Tab>
            {/* <Tabs.Tab value="billing" icon={<IconPhoto size="1rem" />}>
            Billing
          </Tabs.Tab> */}
            <Tabs.Tab value="account" icon={<IconSettings size="1rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>
        </StyledTabs>
      </Center>
      <Title order={3}>Account Dashboard</Title>
      <Grid>
        <Grid.Col xs={4}>
          <Paper shadow="md" p="md">
            <UnstyledButton>
              <Group>
                <Avatar size={40} color="blue">{dashboardState.firstName.charAt(0)}{dashboardState.lastName.charAt(0)}</Avatar>
                <div>
                  <Text>{dashboardState.firstName} {dashboardState.lastName}</Text>
                  <Text size="xs" color="dimmed">{dashboardState.email}</Text>
                </div>
              </Group>
              <Space h="sm" />
              <Badge color="teal">Free Tier Plan</Badge>
            </UnstyledButton>
            <Divider my="sm" />
            <Group position='apart'>
              <Button compact>Edit Billing</Button>
              <Button compact onClick={() => {
                  onLogout()
                }}>Logout</Button>
            </Group>
          </Paper>
        </Grid.Col>

        {/* <Divider my="sm" /> */}
        <Grid.Col xs={8}>
          <Paper shadow="md" p="md">
            <Title order={4}>Welcome back {dashboardState.firstName}!</Title>
            <Group position="apart">
              <Group>
                <Text>API Calls Last 24h</Text>
                <RingProgress
                  sections={[{ value: 1, color: 'blue' }]}
                  label={
                    <Text color="blue" weight={700} align="center" size="xl">
                      2
                    </Text>
                  }
                />
              </Group>
              <Group>
                <Text>Snappy API Req</Text>
                <RingProgress
                  sections={[{ value: getPercentQuotaUsed(dashboardState.monthlyApiCallsLimit, dashboardState.apiCallsCount), color: 'blue' }]}
                  label={
                    <Text color="blue" weight={700} align="center" size="xl">
                      {dashboardState.apiCallsCount}/{dashboardState.monthlyApiCallsLimit}
                    </Text>
                  }
                />
              </Group>
            </Group>
            <Space h="sm" />
            <Group>            
              <Code>API Key: {dashboardState.apiKey}</Code>
            </Group>       
            <Text size="sm">Key Last Used: {dashboardState.lastApiCall}</Text>
            <Text>API Reference:</Text>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
}