
import { createStyles, Paper, Text, ThemeIcon, rem, Container, SimpleGrid, Divider, List, Center, Badge, Title } from '@mantine/core';
import { IconCamera, IconCheck, IconCircleCheck, IconColorSwatch, IconDownload, IconSettings, IconUser } from '@tabler/icons-react';
import React from 'react';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,
    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.02)',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.blue[3], theme.colors.blue[6]),
    },
  },
  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
  featureItemText:{
    marginBottom:'2.5px',
    lineHeight:'1'
  }
}));

interface CardGradientProps {
  title: string;
  description: string;
}

export function ThreeTieredPricing({ title, description }: CardGradientProps) {
  const { classes } = useStyles();
  return (
    <Container size="lg">
      <Title className={classes.title} order={2}>
        Our simple pricing options
      </Title>
      <SimpleGrid cols={3} breakpoints={[
        { maxWidth: 'sm', cols: 1 },
        { minWidth: 'sm', cols: 2 },
        { minWidth: 'sm', cols: 3 },
      ]}>
      <Paper withBorder radius="md" className={classes.card}>
        <Badge>Coming Soon</Badge>
        <Text size="xl" weight={500} mt="md">
          Pro 
        </Text>
        <Text size="sm" mt="sm" color="dimmed">
          This is the description of the premium plan.
        </Text>
        <Divider my="sm" />
        <List
          spacing="xs"
          size="sm"
          center
          icon={
            <Center>
              <ThemeIcon color="teal" size={16} radius="xl">
                  <IconCheck size={18} />
              </ThemeIcon>
            </Center>
          }>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">2,000 Site Snapshots</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">2,000 Site Comparisons</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">Priority API Access</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text fz="md" className={classes.featureItemText}>Priority Email Support</Text>
            </Center>
          </List.Item>
        </List>
      </Paper>
      <Paper withBorder radius="md" className={classes.card}>
        <Badge>Coming Soon</Badge>
        <Text size="xl" weight={500} mt="md">
          Free Plan
        </Text>
        <Text size="sm" mt="sm" color="dimmed">
          Get started with Snappy for free
        </Text>
        <Divider my="sm" />
        <List
          spacing="xs"
          size="sm"
          center
          icon={
            <Center>
              <ThemeIcon color="teal" size={16} radius="xl">
                  <IconCheck size={18} />
              </ThemeIcon>
            </Center>
          }>
          
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">150 Site Snapshots</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text fz="md" className={classes.featureItemText}>150 Snapshot Comparisons</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text fz="md" className={classes.featureItemText}>Email Support</Text>
            </Center>
          </List.Item>
      </List>
      </Paper>
      <Paper withBorder radius="md" className={classes.card}>
      <Badge>Coming Soon</Badge>
        <Text size="xl" weight={500} mt="md">
          Enterprise
        </Text>
        <Text size="sm" mt="sm" color="dimmed" >
        
          Best suited for enterprise teams 
        </Text>
        <Divider my="sm" />
        <List
          spacing="xs"
          size="sm"
          center
          icon={
            <Center>
              <ThemeIcon color="teal" size={16} radius="xl">
                  <IconCheck size={18} />
              </ThemeIcon>
            </Center>
          }>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">Unlimited Site Snapshots</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">Unlimited Site Comparisons</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text className={classes.featureItemText} fz="md">Priority API Access</Text>
            </Center>
          </List.Item>
          <List.Item>
            <Center>
              <Text fz="md" className={classes.featureItemText}>Phone and Email Support</Text>
            </Center>
          </List.Item>
        </List>
      </Paper>
      </SimpleGrid>
      
    </Container>
  );
}