import { Tooltip, Code, Button, Switch, Container, Grid, Image, Title, Flex, List, ThemeIcon, Skeleton, Textarea, Paper, TextInput, Progress, Text, Center, ActionIcon, Slider, Divider, Space, Group }
  from '@mantine/core';
import axios from 'axios';
import SizeSlider from '../Components/SizeSlider'
import HomepagePromo from './HomepagePromo';
import HomepageComingSoon from './HomepageComingSoon';
import React, { useState, useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IconDownload } from '@tabler/icons';
import CopyButton from '../Components/CopyButton'
import { useForm } from '@mantine/form';
import { isValidURL } from '../snappyUtil';
import HelpRollover from '../Components/HelpRollover'

export default function SimpleScreenshot() {
  const dummyForm = useForm({
    initialValues: {
      url: "",
      fullPageScreenshot: false,
      waitForNetworkIdle: false,
      screenSize: "1920x1080",
      userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/119.0.0.0 Safari/537.36",
      cookies: "[ {\"name\":\"examplecookie1\", \"value\":\"349\", \"path\":\"/\", \"domain\":\"sitesnappy.com\"}, {\"name\":\"cookie2\", \"value\":\"1\", \"path\":\"/\", \"domain\":\"sitesnappy.com\"}]",
      captchaResponse: ""
    },
    validate: {
      url: (value) => (!isValidURL(value) && value !== '' ? 'Please enter a valid URL' : null)
    }
  });

  const [screenshotResult, setScreenshotResult] = useState({
    screenshotUrl: "",
    isSuccess: false
  })

  const [screenshotState, setScreenshotState] = useState({
    loading: false
  })

  async function getScreenshot(values) {
    
    if (!executeRecaptcha) 
      return;
    setScreenshotState({ loading: true })
    const token = await executeRecaptcha();

    console.log(values, 'form values');
    let requestData =  {method: 'post', url: `${process.env.REACT_APP_SNAPPY_API_URL}/snapshot`, data: values };
    requestData.data.captchaResponse = token

    const res = axios(requestData)
      .then(response => {
        console.log(response.data, "response data");

        setScreenshotResult({
          screenshotUrl: response.data.screenshotUrl,
          isSuccess: response.data.isSuccess
        });
        if (response.data.errorMessage !== '') {
          alert(response.data.errorMessage)
        }
      })
      .catch(error => {
        console.log(error);
        alert("Error reaching screenshot server. Please try again soon.");
      })
      .finally(() => {
        setScreenshotState({ loading: false })
      });
    console.log(res);
  }
  //From https://github.com/t49tran/react-google-recaptcha-v3/issues/16
  const { executeRecaptcha } = useGoogleReCaptcha();

  return <>
    <Container my="md">
      <form onSubmit={dummyForm.onSubmit((values) => {getScreenshot(values); } )}>
      <Grid>
        <Grid.Col xs={12}>
          <Flex align="center" gap="md">
            <TextInput
                style={{ minWidth: "260px", width: "90%", height: "70px" }}
                placeholder="https://wikipedia.org"
                label="Webpage"
                {...dummyForm.getInputProps('url')}
              />
              <Button type="submit" style={{ marginTop: "15px" }} disabled={screenshotState.loading}>
                Get screenshot!</Button>           
            </Flex>
        </Grid.Col>
        <Grid.Col xs={4} order={2} orderXs={1}>
          <Paper shadow="md" p="md" withBorder>
            <Title order={4} align="center">Browser Options</Title>
            <Divider my="sm" />
            <Text>Viewport Size</Text>
            <SizeSlider {...dummyForm.getInputProps('screenSize')} heightDisabled={dummyForm.fullPageScreenshot} />
            <Space h="sm" />
            <Group gap="sm">
              <Switch
                {...dummyForm.getInputProps('fullPageScreenshot', { type: 'checkbox' })}
                label="Fullpage Screenshot">
              </Switch>
              <HelpRollover rolloverText="Beta: Screenshots are sized to the entire length of the rendered page"></HelpRollover>
            </Group>
            <Space h="sm" />
            <Group gap="sm">
            <Switch
              {...dummyForm.getInputProps('waitForNetworkIdle', { type: 'checkbox' })}
              label="Wait for Network Idle"></Switch>
              <HelpRollover rolloverText="Capture screeenshot when there has been no network activity for at least 500 milliseconds"></HelpRollover>
            </Group>
            <Space h="sm" />
            <Textarea
              style={{ width: "100%" }}
              placeholder=""
              autosize
              name="userAgent"
              {...dummyForm.getInputProps('userAgent')}
                label={
                  <Group>
                    User Agent
                    <HelpRollover rolloverText={"User agent string to be sent along with the screenshot web request"}/>
                  </Group>
                }
            /><Space h="sm" />
            <Textarea
              style={{ width: "100%" }}
              placeholder=""
              autosize
              name="cookies"
              {...dummyForm.getInputProps('cookies')}
              label={
                <Group>
                  Cookies
                  <HelpRollover rolloverText={"Cookies to be sent along with the screenshot web request. (Formatted in JSON)"}/>
                </Group>
              }
            />
            <br></br>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={8} order={1} orderXs={2}>
          <Paper shadow="md" p="md" withBorder>
            <Title order={4} align="center">Easily Capture Webpage Images</Title>
            <Divider my="sm" />
            <div hidden={screenshotResult.screenshotUrl || screenshotState.loading}>
              <HomepagePromo></HomepagePromo>
            </div>
            <div hidden={!screenshotState.loading}>
              <Progress color="green" radius="md" size="lg" value={100} animate striped />
              <Text ta="center">Generating Screenshot...</Text>
              <Divider my="sm" />
            </div>
            <div hidden={!screenshotResult.screenshotUrl}>
              <Image
                radius="md"
                src={screenshotResult.screenshotUrl}
                alt="Screenshot Image"
              />
              <br></br>
              <Flex mih={50} gap="md" justify="center" align="flex-start" direction="row" wrap="wrap">
                <a href={screenshotResult.screenshotUrl} target="_blank">
                  <Button leftIcon={<IconDownload size={14} />}>
                    Download image
                  </Button>
                </a>
                <CopyButton shareUrl={screenshotResult.screenshotUrl}></CopyButton>
              </Flex>
            </div>
            <Divider hidden={!screenshotResult.screenshotUrl} my="sm" />
            <HomepageComingSoon></HomepageComingSoon>
          </Paper>
        </Grid.Col>
      </Grid>
      </form>
    </Container>
  </>;
}
