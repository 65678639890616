import { useState, useEffect } from 'react';
import {
  AppShell,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import PrivacyPolicy from './Pages/PrivacyPolicy'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import './App.css';
import SimpleScreenshot from './Widgets/SimpleScreenshot';
import { HeaderResponsive } from './Navigation/HeaderResponsive.tsx';
import FAQs from './Pages/FAQs';
import Login from './Pages/Login';
import AboutUs from './Pages/About-us';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';
import DashboardHome from './Pages/Dashboard/DashboardHome.tsx';
import ProtectedRoute from './Components/ProtectedRoute';
import clearCookie from './snappyUtil'
import { FooterLinks } from './Navigation/FooterLinks.tsx';
import './bluegradient.css'; 
import ContactUs from './Pages/Contact-us';

function App() {
  const theme = useMantineTheme();
  //const [opened, setOpened] = useState(false); //this originally was for some menu state?

  const topNavlinks = 
    [
      {
        "link": "/",
        "label": "Snapshot the Web"
      },
      {
        "link": "/about-us-and-our-mission",
        "label": "About Us"
      },
      // {
      //   "link": "#",
      //   "label": "Pricing"
      // },
      {
        "link": "/faqs",
        "label": "FAQs"
      }
    ];

  const aboutUsSection = 
    [
      {
        "link": "/faqs",
        "label": "FAQs"
      },
      {
        "link": "/contact-us",
        "label": "Contact Us"
      },
      {
        "link": "/privacy-policy",
        "label": "Privacy Policy"
      }
    ];

    const gettingStartedSection = 
    [
      {
        "link": "/",
        "label": "Take a Snapshot"
      },
      // {
      //   "link": "/login",
      //   "label": "Free No Code API"
      // },
      {
        "link": "/about-us-and-our-mission",
        "label": "Premium Plans"
      }
    ]

  const [ user, setUser ] = useState([]);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [ isUserLoggedIn, setIsUserLoggedIn ] = useState(false);
  const navigate = useNavigate();

  const login = useGoogleLogin({
      onSuccess: function (codeResponse) {
        setIsLoginLoading(true);
        console.log(codeResponse, "code response"); //setUser(codeResponse)
          axios
              .get(`${process.env.REACT_APP_SNAPPY_API_URL}/auth/verify-token?authorizationCode=${codeResponse.code}`, { withCredentials: true })
              .then((res) => {
                console.log(res.data, "local response"); 
                setUser(res.data.user.given_name);    
                if(res.data.logInSuccessful){
                  setIsUserLoggedIn(true);
                }                         
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setIsLoginLoading(false);
              });
      },
      flow: 'auth-code',
      onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    let isLoggedIn = document.cookie.match(new RegExp('(^| )' + 'isLoggedIn' + '=([^;]+)'));
    setIsUserLoggedIn(isLoggedIn);
    
    var userName = document.cookie.match(new RegExp('(^| )' + 'userName' + '=([^;]+)'));
    if (userName){
      setUser(userName[2]);
    } 

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;
      const currentScrollPosition = window.scrollY;
      const maxScroll = fullHeight - windowHeight;
      const percentageScrolled = (currentScrollPosition / maxScroll) * 100;
      setScrollPercentage(percentageScrolled);
      setScrollPosition(currentScrollPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);

  useEffect(() => {
    if(isUserLoggedIn){
      navigate("/dashboard")
    } 
  }, [isUserLoggedIn])

  const logOut = () => {
      clearCookie('isLoggedIn','snappytest.com','/');
      clearCookie('userName','snappytest.com','/');
      setIsUserLoggedIn(false);
      navigate('/')
  };
  // const { classes } = useStyles();

  //TODO: Another issue is, will need to add some margin above grass div
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  return (   
      <>
      <div style={{top:`${scrollPosition - 65}px`}} className={`gradient-container ${scrollPercentage >= 40 ? 'show' : ''}`}></div>
      <AppShell 
        styles={{
          main: {
            // background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
            paddingTop: "0px",
            marginBottom: "0",
            minHeight: "92vh",
            zIndex:"1"
          },
        }}
        // navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        footer={
          <FooterLinks data={[{title: 'Get Started', links: gettingStartedSection},{title: 'About Us', links: aboutUsSection}]}>
          </FooterLinks>
        }
        header={
          <HeaderResponsive links={topNavlinks} currentProfile={user} isUserLoggedIn={isUserLoggedIn}></HeaderResponsive>
        }
      >
        <Routes>
          <Route path="/" element={
            <GoogleReCaptchaProvider reCaptchaKey="6LfcvTwkAAAAAKRtH_9xy0UF1v3x9NyekiQXITEi">
              <SimpleScreenshot />
            </GoogleReCaptchaProvider>} />
              <Route path="/about-us-and-our-mission" element={<AboutUs />} />
              <Route path="/Contact-us" element={
              <GoogleReCaptchaProvider reCaptchaKey="6LfcvTwkAAAAAKRtH_9xy0UF1v3x9NyekiQXITEi">
                <ContactUs />
              </GoogleReCaptchaProvider>          
              } />
              <Route path="/FAQs" element={<FAQs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/login" element={<Login onLoginClick={() => login()} onSignupClick={login} isBackendLoading={isLoginLoading}/>} />
              <Route path="/dashboard" 
                  element={
                    <ProtectedRoute isLoggedIn={isUserLoggedIn}>
                      <DashboardHome onLogout={logOut}/>
                    </ProtectedRoute>
                  } />
        </Routes>     
      </AppShell> 
      </>
  );
}

export default App;
