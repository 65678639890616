import React from 'react'
import { Button, Switch, Container, Grid, Image, Title, Skeleton, Textarea, Paper, TextInput, Progress, Text, Center, ActionIcon, Slider, Divider, List, ThemeIcon }
    from '@mantine/core';
import { IconCircleCheck, IconCircleDashed, IconSettings, IconCamera, IconDownload, IconUser, IconCodeAsterix, IconManualGearbox } from '@tabler/icons';

export default function HomepagePromo() {
    return (
        <>
            <Container px={0}>
                <Text align="center" style={{paddingBottom:"15px"}}>Free tool to generate website snapshots!</Text>
                <Divider style={{ width: "10%", textAlign: "center", margin: "0 auto", paddingTop:"12px" }} my="sm" />
                <List
                    spacing="xs"
                    size="sm"
                    center
                    icon={
                        <ThemeIcon color="teal" size={34} radius="xl">
                            <IconCircleCheck size={18} />
                        </ThemeIcon>
                    }                   
                >
                    <List.Item icon={
                        <ThemeIcon color="teal" size={40} radius="xl">
                            <IconCamera size={30} />
                        </ThemeIcon>
                    }><Text fz="md" style={{lineHeight: 1}}>Site Snap provides a simple interface to capture snapshots of any website.</Text></List.Item>
                    <List.Item icon={
                        <ThemeIcon color="orange" size={40} radius="xl">
                            <IconSettings size={30} />
                        </ThemeIcon>
                    }><Text fz="md" style={{lineHeight: 1}}>Customize window dimensions to simulate different viewport sizes.</Text></List.Item>
                    <List.Item icon={
                        <ThemeIcon color="teal" size={40} radius="xl">
                            <IconUser size={30} />
                        </ThemeIcon>
                    }><Text fz="md" style={{lineHeight: 1}}>Set User Agent and Cookies for your specific testing requirements. </Text></List.Item>
                    <List.Item icon={
                        <ThemeIcon color="blue" size={40} radius="xl">
                            <IconDownload size={30} />
                        </ThemeIcon>
                    }><Text fz="md" style={{lineHeight: 1}}>Share and download your snapshots via a generated URL.</Text></List.Item>
                </List>
                <br></br>               
            </Container>
        </>
    )
}
