import { List, Text, ThemeIcon, Space,Divider } from '@mantine/core';
import { IconCodeAsterix, IconManualGearbox } from '@tabler/icons';
import React from 'react';

export default function HomepagePromo() {
    return (
        <>
             <Text align="center" style={{paddingBottom:"15px"}}>Features Coming Soon:</Text>
             <Divider style={{ width: "10%", textAlign: "center", margin: "0 auto", paddingTop:"12px" }} my="sm" />
                <List
                    spacing="xs"
                    size="sm"
                    center
                >
                    <List.Item
                        icon={
                            <ThemeIcon color="violet" size={40} radius="xl">
                                <IconCodeAsterix size={30} />
                            </ThemeIcon>
                        }
                    >
                        <Text fz="md" style={{lineHeight: 1}}>With provided API key, send a POST request to retreive a Snap Shot</Text>
                    </List.Item>
                    <List.Item
                        icon={
                            <ThemeIcon color="blue" size={40} radius="xl">
                                <IconManualGearbox size={30} />
                            </ThemeIcon>
                        }
                    >
                        <Text fz="md" style={{lineHeight: 1}}>No code API: Auto generated REST endpoints to capture Snap Shots</Text>
                    </List.Item>
                </List> 
        </>
    )
}
