import { useState } from 'react';
import axios from 'axios';
import { TextInput, Text, Textarea, SimpleGrid, Group, Title, Button, Container, Select, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function ContactUs() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submissionState, setSubmissionState] = useState({
    isSubmitted: false,
    isLoading: false
  })

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      subject: "General Inquiry",
      message: '',
    },
    validate: {
      name: (value) => value.trim().length < 2,
      email: (value) => !/^\S+@\S+$/.test(value)
    },
  });

  async function submitForm(values) {
    if (!executeRecaptcha)
      return;

    setSubmissionState({ ...submissionState, isLoading: true });
    const token = await executeRecaptcha();
    let requestData = { method: 'post', url: `${process.env.REACT_APP_SNAPPY_WEBAPI_URL}/contact`, data: values };
    requestData.data.captchaResponse = token

    const res = axios(requestData)
      .then(response => {
        console.log(response.data, "response data");
        if (response.data.errorMessage !== '') {
          alert(response.data.errorMessage)
        } else {
          setSubmissionState(values => ({
            ...values, ['isSubmitted']: true
          }));
        }
      })
      .catch(error => {
        console.log(error);
        alert("Error reaching server. Please try again soon.");
      })
      .finally(() => {
        setSubmissionState(values => ({
          ...values, ['isLoading']: false
        }));
      });
  }

  return (
    <>
      <Space h="xl"></Space>
      <Container size="xs">
        <form onSubmit={form.onSubmit((values) => { submitForm(values); })} hidden={submissionState.isSubmitted} >
          <Title
            order={2}
            size="h1"
            style={{ fontFamily: 'Greycliff CF, var(--mantine-font-family)' }}
            fw={900}
            ta="center"
          >
            Get in touch
          </Title>
          <Space h="sm"></Space>
          <Text>
            Leave a question, comment, or bug description and we will get in touch with you shortly. 
            {/* Or you can contact us directly [Email] */}
          </Text>
          <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
            <TextInput
              label="Name"
              placeholder="Your name"
              name="name"
              variant="filled"
              {...form.getInputProps('name')}
            />
            <TextInput
              label="Email"
              placeholder="Your email"
              name="email"
              variant="filled"
              {...form.getInputProps('email')}
            />
            <Select
              label="Subject"
              name="subject"
              data={['General Inquiry', 'Feature Request', 'Bug Report']}
              {...form.getInputProps('subject')}
            />
          </SimpleGrid>
          <Textarea
            mt="md"
            label="Message"
            placeholder="Your message"
            maxRows={10}
            minRows={5}
            autosize
            name="message"
            variant="filled"
            {...form.getInputProps('message')}
          />

          <Group justify="center" mt="xl">
            <Button type="submit" size="md" disabled={submissionState.isLoading}>
              {submissionState.isLoading ? "Sending" : "Send"} message
            </Button>
          </Group>
        </form>

        <div hidden={!submissionState.isSubmitted}>
          <Title
            order={2}
            size="h1"
            style={{ fontFamily: 'Greycliff CF, var(--mantine-font-family)' }}
            fw={900}
            ta="center"
          >
            Talk to you soon
          </Title>
          <Space h="xl"></Space>
          <Text >Thank you for your submission. We will get back to you shortly.</Text>
        </div>
      </Container>
    </>
  );
}