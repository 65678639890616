import React , { useState } from 'react';
import { createStyles, Header, Container, Group, Burger, Paper, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';

const HEADER_HEIGHT = 60;
const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 2,
    margin: 0
  },

  logoLink:{
    color: 'inherit',
    textDecoration: "none"
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 3,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

const getAuthButtons = function(cx, classes, currentProfile, location, isLoggedIn){
  return (<></>)
  // console.log(isLoggedIn, "isLoggedIn ?")
  // if(isLoggedIn){
  //   return (
  //     <Link        
  //       key={"Dashboard"}
  //       to={"/dashboard"}
  //       className={cx(classes.link, { [classes.linkActive]: location.pathname === "/dashboard" })}>
  //       {currentProfile}'s Dashboard
  //     </Link>
  //   )
  // }else{
  //     return (<Link 
  //       hidden={true}
  //       key={"Login"}
  //       to={"/login"}
  //       className={cx(classes.link, { [classes.linkActive]: location.pathname === "/login" })}>
  //       Login
  //     </Link>
  //     )
  // }
}

interface HeaderResponsiveProps {
  links: { link: string; label: string }[];
  currentProfile: string
  isUserLoggedIn: boolean
}

export function HeaderResponsive({ links, currentProfile, isUserLoggedIn }: HeaderResponsiveProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const location = useLocation();
  
  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: location.pathname === link.link })}
      >
      {link.label}
    </Link>
  ));

  return (  
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container className={classes.header}>
        <Link to={"/"} className={classes.logoLink}>
          <div style={{display:"flex"}}>
            <img src="rocketv1.png" height={55} style={{marginTop:"10px"}}></img>
            <h2 style={{marginLeft:"3px"}}>Site Snappy</h2>
          </div>
        </Link>
        

        <Group spacing={5} className={classes.links}>
          {items}         
          { getAuthButtons(cx, classes, currentProfile, location, isUserLoggedIn) }
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles} onClick={close}>
              {items}
              { getAuthButtons(cx, classes, currentProfile, location, isUserLoggedIn) }
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}