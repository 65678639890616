import { createStyles, Image, Accordion, Grid, Col, Container, Title } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
  },
}));

const placeholder =
  'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.';

export default function FaqWithImage() {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container size="lg">
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            <Image src="faq.svg" alt="Frequently Asked Questions" />
          </Col>
          <Col span={12} md={6}>
            <Title order={2} ta="left" className={classes.title}>
              Frequently Asked Questions
            </Title>

            {/* It will would be cool if this animated upon page load */}
            <Accordion chevronPosition="right" defaultValue="reset-password" variant="separated">
              <Accordion.Item className={classes.item} value="reset-password">
                <Accordion.Control>What is Site Snappy?</Accordion.Control>
                <Accordion.Panel>Site Snappy is a web app and API used to create visual screenshots of webpages</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="who">
                <Accordion.Control>Who is Site Snappy for?</Accordion.Control>
                <Accordion.Panel>Anyone who needs web screenshots. Screenshots can be used for End-to-End (E2E) testing, regression testing, or even website content. </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="another-account">
                <Accordion.Control>Where and how are snapshots taken?</Accordion.Control>
                <Accordion.Panel>Snapshots can either be taken in web browser using SiteSnappy.com or with the Snappy API. Site Snappy renders the page in a real browser and captures an image
                  of the result.
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="newsletter">
                <Accordion.Control>Can I link Snapshots on my site?</Accordion.Control>
                <Accordion.Panel>Yes, the URLs of Snapshots will remain active for 12 months</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="help">
                <Accordion.Control>I need help or have a technical issue. Who can I contact?</Accordion.Control>
                <Accordion.Panel>The best place to reach us is by email. Please contact <a href="mailto:help@sitesnappy.com">help@sitesnappy.com</a></Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="api">
                <Accordion.Control>How can I use the API?</Accordion.Control>
                <Accordion.Panel>The API is not availiable yet but will soon be released.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="schedule">
                <Accordion.Control>Is there a way to schedule snapshots?</Accordion.Control>
                <Accordion.Panel>We plan to add this feature shortly after the launch of the API.</Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}